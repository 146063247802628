<template>
  <div class="flex flex-col gap-y-2">
    <div id="reminders-report-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="reminders-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">

              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.date_from"
                labelAddon="Od"
                :markDirty="true"
                :error="saveErrors.date_from"
              />

              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.date_to"
                labelAddon="Do"
                :markDirty="true"
                :error="saveErrors.date_to"
              />

              <div class="hidden sm:flex w-full md:min-w-max flex-1 items-center">
                <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-left" size="lg" />
                </button>
                <button @click="setInitDates" class="tw--btn tw--btn-secondary">domyślnie</button>
                <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-right" size="lg" />
                </button>
              </div>

            </div>

            <div class="flex flex-wrap items-center gap-3">
              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.employee_id"
                label="Agent"
                :options="employeeSimpleList"
                :nullOptionName="showAdmin ? 'wszyscy' : showManager ? 'zespół' : ''"
              />

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.insurer_id"
                label="TU"
                :options="insurerList"
              />

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.type_id"
                label="Typ"
                :options="orderTypeList"
              />
            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex-grow sm:hidden flex items-center">
            <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-left" size="lg" />
            </button>
            <button @click="setInitDates" class="tw--btn tw--btn-secondary">domyślnie</button>
            <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-right" size="lg" />
            </button>
          </div>
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="init" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap gap-x-3 gap-y-1">
        <button @click="getReport" class="tw--btn">Generuj raport</button>
        <button @click="checkAll" class="tw--btn-icon flex items-center gap-1" title="Zanzacz/odznacz wszystko">
          <FontAwesomeIcon :icon="checkedAll ? ['far', 'minus-square'] : ['far', 'check-square']" size="lg" class="p-0" />
          <!-- <span>{{ checkedAll ? 'usuń zanzaczenie' : 'zaznacz wszystkie' }}</span> -->
        </button>
        <button @click="printList" class="tw--btn" :disabled="rows.length === 0"><FontAwesomeIcon icon="print" /> Drukuj listę</button>
        <button @click="remindSelected" class="tw--btn" :disabled="rowSelected === 0"><FontAwesomeIcon icon="clipboard-check" /> Oznacz jako 'powiadomiono'</button>
        <button v-if="prm" @click="printSelected" class="tw--btn" :disabled="rowSelected === 0"><FontAwesomeIcon icon="print" /> Drukuj pisma</button>
        <button v-if="prm" @click="printEnvSelected" class="tw--btn" :disabled="rowSelected === 0"><FontAwesomeIcon icon="print" /> Drukuj koperty</button>
        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">znaleziono</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ rows.length }} rat</span>
        </div>
      </div>
    </div>

    <div id="reminders-report-content" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">

      <div id="reminder-table" class="flex flex-col gap-1">
        <div class="flex flex-wrap border dark:border-gray-600 rounded shadow" v-for="row in rows" :key="row.id">

          <div class="order-1 w-1/2 md:w-8 flex items-center md:justify-center px-2">
            <label><input type="checkbox" true-value="1" false-value="0" v-model="row.checked"></label>
          </div>

          <div class="order-3 w-full md:w-32 flex flex-row md:flex-col justify-between px-2">
            <div class="flex gap-1 md:justify-end">
              <div><FontAwesomeIcon :icon="['far', 'calendar-alt']" title="data należności raty" class="text-muted" /></div><div>{{ row.due_date_h }}</div>
            </div>
            <div class="text-right">{{ row.value | moneyPL }}</div>
          </div>

          <div class="order-4 flex-grow flex flex-col px-2">
            <div class="flex gap-2">
              <div class="text-muted">klient</div>
              <div>
                <router-link :to="{ name: 'Customer', params: { id: row.customer_id } }" class="tw--link whitespace-nowrap overflow-x-hidden" target="_blank">
                  {{ row.customer_name }}
                </router-link>
              </div>
            </div>
            <div v-if="row.address_id === '' && prm" class="text-red-500 text-xs italic">Brak adresu</div>
            <div v-show="row.phone !== ''">
              <a :href="`tel:${row.phone}`" class="tw--link flex items-center gap-x-1">
                <FontAwesomeIcon icon="phone-alt" /><span>{{ row.phone }}</span>
              </a>
            </div>
          </div>

          <div class="order-5 w-64 flex flex-col px-2">
            <div>{{ row.insurer_name }}</div>
            <div>{{ row.type_name }}</div>
          </div>

          <div class="order-6 w-48 flex flex-col justify-between px-2">
            <div v-if="!prm || (row.bank_acc_no !== '' && prm)" class="text-muted text">polisa:</div>
            <router-link :to="{ name: 'Order', params: { id: row.order_id } }" class="tw--link" target="_blank">
              {{ row.policy_no }}
            </router-link>
            <div v-if="row.bank_acc_no === '' && prm" class="text-red-500 text-sm italic">Brak konta bank.</div>
          </div>

          <div class="order-7 w-48 flex items-center gap-x-1 px-2">
            <FontAwesomeIcon icon="user-tie" class="text-muted" />
            <div class="whitespace-nowrap overflow-x-hidden">{{ row.employee_name }}</div>
          </div>

          <div class="order-2 md:order-8 w-1/2 md:w-auto flex gap-x-1 justify-end px-2">
            <button @click="remind(row.id)" class="tw--btn-icon" title="oznacz ratę jako 'powiadomiono klienta'">
              <FontAwesomeIcon :icon="['fas', 'clipboard-check']" :class="{'text-2xl': !prm}" />
            </button>
            <div v-if="prm" class="flex gap-x-1">
              <button
                @click="printNotif(row.id)"
                class="tw--btn-icon"
                :title="row.address_id === '' || row.bank_acc_no === '' ? 'Wydruk niemożliwy - uzupełnij brakujące elementy' : 'Drukuj pismo do klienta'"
                :disabled="row.address_id === '' || row.bank_acc_no === ''"
              >
                <FontAwesomeIcon :icon="['fas', 'print']" />
              </button>
              <button
                @click="printEnv(row.id)"
                class="tw--btn-icon"
                :title="row.address_id === '' || row.bank_acc_no === '' ? 'Wydruk niemożliwy - uzupełnij brakujące elementy' : 'Drukuj kopertę'"
                :disabled="row.address_id === '' || row.bank_acc_no === ''"
              >
                <FontAwesomeIcon :icon="['far', 'envelope']" />
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- tabela do wydruku DIV HTML -->
      <table class="hidden" id="printTable">
        <thead>
          <tr>
            <th>
              &nbsp;
            </th>
            <th>Data należn.</th>
            <th>Kwota</th>
            <th>Klient / telefon</th>
            <th>TU / produkt</th>
            <th>Nr polisy</th>
            <th>Agent</th>
          </tr>
        </thead>
        <tbody v-if="showToPrint">
          <tr v-for="row in rows" :key="row.id">
            <td><label><input type="checkbox"></label></td>
            <td>{{ row.due_date_h }}</td>
            <td>{{ row.value | moneyPL }}</td>
            <td>{{ row.customer_name }}<br v-if="row.phone !== ''">tel: {{ row.phone }}</td>
            <td>{{ row.insurer_name }}<br>{{ row.type_name }}</td>
            <td>polisa: {{ row.policy_no }}</td>
            <td>{{ row.employee_name }}</td>
          </tr>
        </tbody>
      </table>

    </div> <!-- sales-report-content -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import { confirm } from '../../mixins/confirm';
import moment from 'moment';
import ReportsService from '../../services/ReportsService';
import OrderService from '../../services/OrderService';

export default {
  name: 'InstReminder',
  mixins: [errorHandler, confirm],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      rows: [],
      printRemindersModule: '0',
      activeFilters: {
        date_from: '',
        date_to: '',
        employee_id: '',
        insurer_id: '',
        type_id: '',
      },
      loading: false,
      saveErrors: {},
      showToPrint: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUserType',
    ]),
    ...mapGetters('products', [
      'insurerList',
    ]),
    ...mapState('employees', [
      'employeeSimpleList',
    ]),
    ...mapGetters('orders', [
      'orderTypeList',
    ]),
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    showManager() {
      return this.currentUserType === 'manager';
    },
    prm() {
      if (this.printRemindersModule === '1') {
        return true;
      }
      return false;
    },
    rowSelected() {
      return this.rows.filter(obj => obj.checked === '1').length;
    },
    checkedAll() {
      if (this.rows.find(obj => obj.checked === '0' || obj.checked === undefined) === undefined) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    if (this.orderTypeList.length === 0) this.$store.dispatch('orders/getOrderTypeList').catch(error => this.resolveError(error));
    this.init();
  },
  methods: {
    init() {
      // Filtry
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      if (this.activeFilters.date_from === '' || this.activeFilters.date_to === '') {
        this.setToday();
      }
      this.getReport();
    },
    getReport() {
      this.$store.dispatch('setLoadingData', true);
      this.saveErrors = {};
      ReportsService.getInstReminderReport(this.activeFilters)
        .then((response) => {
          this.rows = response.data.rows;
          this.printRemindersModule = response.data.print_reminders_module !== undefined ? response.data.print_reminders_module : '0';
          this.$store.dispatch('setLoadingData', false);
          this.setUrl();
        })
        .catch((error) => {
          this.rows = [];
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    prevMonth() {
      this.activeFilters.date_from = moment(this.activeFilters.date_from).subtract(1, 'months').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment(this.activeFilters.date_to).subtract(1, 'months').format('YYYY-MM-DD');
    },
    setInitDates() {
      this.setToday();
      this.getReport();
    },
    setToday() {
      this.activeFilters.date_from = moment().format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD');
    },
    nextMonth() {
      this.activeFilters.date_from = moment(this.activeFilters.date_from).add(1, 'months').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment(this.activeFilters.date_to).add(1, 'months').format('YYYY-MM-DD');
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.setToday();
      this.getReport();
    },
    setUrl() {
      const query = {};
      Object.keys(this.activeFilters).forEach((key) => {
        query[key] = encodeURI(this.activeFilters[key]);
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ name: 'InstReminder', query });
      }
    },
    checkAll() {
      if (this.checkedAll) {
        this.rows.forEach((obj) => { obj.checked = '0'; });
      } else {
        this.rows.forEach((obj) => { obj.checked = '1'; });
      }
    },
    remind(id) {
      this.confirm(() => this.confirmedRemind(id), 'Potwierdź zaznaczenie raty jako "powiadomiono klienta".');
    },
    confirmedRemind(id) {
      this.$store.dispatch('setLoadingData', true);
      const formData = new FormData();
      formData.append('inst_ids[]', id);
      OrderService.setInstallmentsAsReminded(formData)
        .then((response) => {
          this.rows = this.rows.filter(obj => obj.id !== id);
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    remindSelected() {
      this.confirm(() => this.confirmedRemindSelected(), 'Potwierdź zaznaczenie wybranych rat jako "powiadomiono klienta".');
    },
    confirmedRemindSelected() {
      this.$store.dispatch('setLoadingData', true);
      const formData = new FormData();
      this.rows.forEach((obj) => {
        if (obj.checked === '1') {
          formData.append('inst_ids[]', obj.id);
        }
      });
      OrderService.setInstallmentsAsReminded(formData)
        .then((response) => {
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
          this.getReport();
        })
        .catch((error) => {
          this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    printNotif(id) {
      const newWin = window.open('');
      newWin.location.href = `${this.apiUrl}/print-reminders?inst_ids[]=${id}`;
    },
    printEnv(id) {
      const newWin = window.open('');
      newWin.location.href = `${this.apiUrl}/print-addresses?inst_ids[]=${id}`;
    },
    printSelected() {
      let getParams = '?';
      this.rows.forEach((obj) => {
        if (obj.checked === '1') {
          getParams = `${getParams}inst_ids[]=${obj.id}&`;
        }
      });
      const newWin = window.open('');
      newWin.location.href = `${this.apiUrl}/print-reminders${getParams}`;
    },
    printEnvSelected() {
      let getParams = '?';
      this.rows.forEach((obj) => {
        if (obj.checked === '1') {
          getParams = `${getParams}inst_ids[]=${obj.id}&`;
        }
      });
      const newWin = window.open('');
      newWin.location.href = `${this.apiUrl}/print-addresses${getParams}`;
    },
    printList() {
      this.showToPrint = true;
      this.$nextTick(() => {
        const divToPrint = document.getElementById('printTable');
        const newWin = window.open('');
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close();
        this.showToPrint = false;
      });
    },
  },
};
</script>